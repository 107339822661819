<template>
  <el-dialog v-model="dialogFormVisible" :title="title" width="800px" @close="close">
    <el-form ref="formRef" label-width="120px" :model="form" :rules="rules">
      <el-form-item label="报名人数总量">
        <el-input type="number" v-model.trim="form.total" disabled />
        <el-tag type="danger">邀请码总共可以被用来报名的人数</el-tag>
      </el-form-item>
      <el-form-item label="已报名人数">
        <el-input type="number" v-model.trim="form.signUpNum" disabled />
        <el-tag type="danger">邀请码已经被报名的人数</el-tag>
      </el-form-item>
      <el-form-item label="剩余可报名人数" prop="defineUseNum">
        <el-input-number v-model="form.defineUseNum" :min="0" :max="100000000" :step="1" :step-strictly="true" />
        <div>
          <el-tag type="danger">邀请码剩余的可以被报名的人数。可修改剩余人数，来增加该邀请码剩余的可报名人数</el-tag>
        </div>
      </el-form-item>

    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import {
  defineComponent,
  getCurrentInstance,
  defineAsyncComponent,
  reactive,
  toRefs,
  onMounted,
} from 'vue'
import { channelInvitationUpdateDefineUseNum } from '@/api/channel'
import { useStore } from 'vuex'
// 引入下拉数选择
export default defineComponent({
  name: 'UserManagementEdit',
  components: {},
  props: {
    eventId: {
      type: String,
      default: '',
    },
    meetingType: {
      type: String,
      default: '',
    },
    identityList: {
      type: Array,
      default: [],
    },
    meetingCode: {
      type: String,
      default: '',
    },
  },
  emits: ['fetch-data'],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance()
    // 自定义手机验证

    const state = reactive({
      formRef: null,
      form: {
        useScene: '',
        name: '',
        identity: '',
      },
      departmentData: [],
      rules: {
        name: [{ required: true, trigger: 'blur', message: '请输入渠道名称' }],
        defineUseNum: [
          { required: true, trigger: 'blur', message: '请输入剩余可报名人数' },
        ],
      },
      title: '',
      dialogFormVisible: false,
      roleList: [],
      userSence: [
        {
          value: 'FORM',
          label: '注册表单',
        },
        {
          value: 'TICKET',
          label: '购票',
        },
      ],
      useSceneDisabled: false,
      identityDisabled: false,
    })

    const showEdit = async (row) => {
      console.log(row)
      state.title = '修改次数'
      row.allNum = row.defineUseNum + row.signUpNum
      state.form = Object.assign({}, row)
      state.dialogFormVisible = true
    }
    const close = () => {
      state['formRef'].resetFields()
      state.dialogFormVisible = false
    }
    const save = () => {
      state['formRef'].validate(async (valid) => {
        if (valid) {
          await channelInvitationUpdateDefineUseNum(
            state.form.channelId,
            state.form.defineUseNum
          )
          proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
          emit('fetch-data')
          close()
        }
      })
    }

    const identityChange = (e) => {
      console.log(e)
      if (e == 'AUDIENCE_EN' || e == 'AUDIENCE') {
        state.form.useScene = ''
        state.useSceneDisabled = false
      } else {
        state.form.useScene = 'FORM'
        state.useSceneDisabled = true
      }
    }

    return {
      ...toRefs(state),
      showEdit,
      close,
      save,
      identityChange,
    }
  },
})
</script>
